<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>
    <v-divider class="mb-1" />
    <v-list>
      <v-list-item>
        <v-img
          src="https://res.cloudinary.com/fitrip/image/upload/v1622281785/belfastlogo_1_yskbx4.png"
        />
      </v-list-item>
    </v-list>
    <v-divider class="mb-2" />
    <v-list
      expand
      nav
    >
      <div />
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
      <v-list-group
        v-for="item in settings"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          :to="child.to"
        >
          <v-list-item-icon>
            <v-icon
              small
              v-text="child.icon"
            />
          </v-list-item-icon>
          <v-list-item-title
            v-text="child.title"
          />
        </v-list-item>
        <!--        <v-list-item-->
        <!--          v-for="([title, icon], i) in cruds"-->
        <!--          :key="i"-->
        <!--          link-->
        <!--        >-->
        <!--          <v-list-item-title v-text="title"></v-list-item-title>-->

        <!--          <v-list-item-icon>-->
        <!--            <v-icon v-text="icon"></v-icon>-->
        <!--          </v-list-item-icon>-->
        <!--        </v-list-item>-->
      </v-list-group>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <template v-slot:append>
      <base-item
        :item="{
          title: 'Logout',
          icon: 'mdi-package-up',
          to: '/login',
        }"
        @click="logout"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      settings: [
        {
          title: 'Settings',
          action: 'mdi-cogs',
          active: false,
          items: [
            {
              icon: 'mdi-package-down',
              title: 'Manage Packages',
              to: '/pages/packages',
            },
            {
              icon: 'mdi-arrange-send-to-back',
              title: 'Facility Category',
              to: '/pages/facility_category',
            },
            {
              icon: 'mdi-animation',
              title: 'Class Category',
              to: '/pages/class_category',
            },
            {
              icon: 'mdi-domain',
              title: 'Branch List',
              to: '/pages/branches',
            },
          ],
        },
      ],
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/dashboard',
        },
        {
          icon: 'mdi-clipboard-check-outline',
          title: 'Check-In',
          to: '/kiosk',
        },
        {
          icon: 'mdi-spa-outline',
          title: 'Classes',
          to: '/pages/classes',
        },
        {
          icon: 'mdi-bookmark-multiple-outline',
          title: 'Bookings',
          to: '/pages/bookings',
        },
        {
          icon: 'mdi-account',
          title: 'Manage Clients',
          to: '/pages/clients',
        },
        {
          icon: 'mdi-account-box-multiple',
          title: 'Manage Users',
          to: '/pages/users',
        },
        {
          icon: 'mdi-format-list-checkbox',
          title: 'Access List',
          to: '/pages/access_list',
        },
        {
          icon: 'mdi-home-city',
          title: 'Facilities',
          to: '/pages/facilities',
        },

        // {
        //   icon: 'mdi-cogs',
        //   title: 'Settings',
        //   to: '/settings',
        //   children: [
        //     {
        //       icon: 'mdi-arrange-send-to-back',
        //       title: 'Facility Category',
        //       to: 'facility_category',
        //     },
        //     {
        //       icon: 'mdi-animation',
        //       title: 'Class Category',
        //       to: 'class_category',
        //     },
        //   ],
        // },
        // {
        //   title: 'typography',
        //   icon: 'mdi-format-font',
        //   to: '/components/typography',
        // },
        // {
        //   title: 'icons',
        //   icon: 'mdi-chart-bubble',
        //   to: '/components/icons',
        // },
        // {
        //   title: 'google',
        //   icon: 'mdi-map-marker',
        //   to: '/maps/google-maps',
        // },
        // {
        //   title: 'notifications',
        //   icon: 'mdi-bell',
        //   to: '/components/notifications',
        // },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },
    methods: {
      logout () {
        localStorage.setItem('token', '')
        this.$router.push('/login')
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : '',
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
